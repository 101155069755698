export const NAV_LINKS = [
	{
		name: "About me",
		path: "/#about",
	},
	{
		name: "Projects",
		path: "/#projects",
	},
	{
		name: "CV",
		path: "/cv",
	},
];

export const CONTACT_LINK = {
	name: "Contact",
	path: "/#contact",
};

export const EXPERIENCES = [
	{
		title: "Front-End Developer",
		company: "CACI Ltd",
		responsibilities: [
			"Developed robust and scalable user interfaces with React for efficient rendering.",
			"Migrated legacy PHP endpoints to Node.js with Express for better performance and maintainability.",
			"Utilized TypeScript for type-safe code and enhanced maintainability.",
			"Employed Tanstack Query for efficient server-side data fetching, caching, and synchronization.",
			"Integrated React Context API for managing complex application state and data flow.",
			"Used Tailwind CSS for rapid UI development and consistent styling across components.",
			"Collaborated with cross-functional teams, adhering to agile methodologies and contributing to code reviews.",
		],
	},
	{
		title: "Full-Stack Developer",
		company: "Luul Solutions Ltd",
		responsibilities: [
			"Resolved issues and enhanced user experience using React, Redux, Node.js, Express, and SQL.",
			"Implemented features with REST APIs, Redux, and state management for better functionality.",
			"Wrote automated tests using Jest and Cypress for code quality and maintainability.",
			"Collaborated with teams to deliver cutting-edge solutions exceeding client expectations.",
			"Utilized Styled Components for visually appealing and intuitive interfaces.",
		],
	},
	{
		title: "Full-Stack Dev Trainee",
		company: "Fast-Track Academy",
		responsibilities: [
			"Leveraging React Props, States, and Class Component Lifecycle to create dynamic and interactive UIs",
			"Mastering React Handling Events & React Forms for rich user experiences",
			"Applying Redux for predictable and robust state management",
			"Utilizing React Hooks for enhanced performance and functionality",
			"Developing RESTful APIs using Fetch API and Axios for seamless communication",
			"Applying best practices in front-end development, using Bootstrap and other popular frameworks",
		],
	},
];

export const EXPERIENCE_ICONS = [
	{ icon: "uim:react", size: "h-11 w-11", name: "React" },
	{ icon: "akar-icons:typescript-fill", size: "h-9 w-9", name: "TypeScript" },
	{ icon: "ri:nodejs-fill", size: "h-11 w-11", name: "Node.js" },
	{ icon: "mdi:tailwind", size: "h-11 w-11", name: "Tailwind CSS" },
	{ icon: "simple-icons:express", size: "h-10 w-10", name: "Express" },
	{ icon: "akar-icons:redux-fill", size: "h-9 w-9", name: "Redux" },
	{ icon: "fontisto:mysql", size: "h-9 w-9", name: "MySQL" },
	{ icon: "fe:git", size: "h-11 w-11", name: "Git" },
	{ icon: "simple-icons:mongodb", size: "h-10 w-10", name: "MongoDB" },
	{ icon: "flowbite:html-solid", size: "h-10 w-10", name: "HTML" },
	{ icon: "flowbite:css-solid", size: "h-10 w-10", name: "CSS" },
	{ icon: "akar-icons:javascript-fill", size: "h-9 w-9", name: "JavaScript" },
];
