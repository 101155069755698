// @ts-ignore
import pdf from "assets/cv.pdf";

import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Link } from "react-router-dom";

type CVPageProps = {};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CVPage = (props: CVPageProps) => {
	const [width, setWidth] = useState(1200);

	useEffect(() => {
		setWidth(window.innerWidth);
	}, [window.innerWidth]);

	return (
		<div className="flex flex-col items-center">
			<Link to={pdf} target="_blank" rel="noopener noreferrer">
				<button className="border rounded-lg border-slate-600 mb-6 hover:border-accent hover:backdrop-brightness-125 py-2 p-4">
					Download CV
				</button>
			</Link>

			<Document file={pdf} className="mb-3 flex justify-start overflow-auto">
				<Page
					pageNumber={1}
					scale={width > 786 ? 1.7 : width / 786}
					renderTextLayer={false}
					renderAnnotationLayer={false}
				/>
			</Document>
		</div>
	);
};

export default CVPage;
