import classNames from "classnames";

type HamburgerProps = {
	open: string;
	setOpen: React.Dispatch<React.SetStateAction<string>>;
	sticky: boolean;
};

const Hamburger = ({ open, setOpen, sticky }: HamburgerProps) => {
	const isOpen = Boolean(open === "true");

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOpen(event.target.checked ? "true" : "false");
	};

	return (
		<div
			className={classNames(
				"menu cross menu--2 translate-x-2 sm:translate-x-6 xl:translate-x-8 transition-all duration-200 z-30",
				open !== "true" && sticky ? "w-24" : "w-32",
				open === "true" && sticky && "w-32"
			)}
		>
			<label>
				<input type="checkbox" checked={isOpen} onChange={handleInputChange} />
				<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
					<circle cx="50" cy="50" r="30" />
					<path className="line--1" d="M0 70l28-28c2-2 2-2 7-2h64" />
					<path className="line--2" d="M0 50h99" />
					<path className="line--3" d="M0 30l28 28c2 2 2 2 7 2h64" />
				</svg>
			</label>
		</div>
	);
};

export default Hamburger;
