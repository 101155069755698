import { Outlet } from "react-router-dom";
import Header from "components/Header";
import ScrollToHashElement from "utils/ScrollToHashElement";

function RootLayout() {
	return (
		<div className="flex flex-col">
			<ScrollToHashElement />
			<Header />

			<main className="container flex-1">
				<Outlet />
			</main>
		</div>
	);
}

export default RootLayout;
