import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useEffect, useRef, useState } from "react";

import Hamburger from "components/common/Hamburger";
// import { CONTACT_LINK, NAV_LINKS } from "lib/constants";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Navlinks = ({ sticky }: { sticky: boolean }) => {
	const [open, setOpen] = useState("");
	const container = useRef<HTMLDivElement>(null);
	const tl = useRef<any>();

	useGSAP(
		() => {
			tl.current = gsap
				.timeline({ paused: true })
				.fromTo(container.current, { y: -512 }, { duration: 0.15, y: 0 })
				.fromTo(
					".nav-item",
					{ opacity: 0, pointerEvents: "none" },
					{ duration: 0.2, opacity: 1, pointerEvents: "auto", stagger: 0.075 },
					"<+=0.15"
				);

			// Check if the animation should play in the forward direction or reverse
			open == "true" && tl.current.play();
			open == "false" && tl.current.reverse(1);
		},
		{ dependencies: [open], scope: container }
	);

	useEffect(() => {
		const handleScroll = (event: Event) => {
			event.preventDefault();
		};

		if (open == "true") {
			window.addEventListener("wheel", handleScroll, { passive: false });
		} else {
			window.removeEventListener("wheel", handleScroll);
		}

		return () => {
			window.removeEventListener("wheel", handleScroll);
		};
	}, [open]);

	return (
		<div>
			{/* <div className="flex items-center gap-6 max-xl:hidden">
				{NAV_LINKS.map((link) => (
					<p
						className="text-lg hover:text-accent transition-colors duration-150 hover:cursor-pointer"
						key={link.name}
					>
						{link.name}
					</p>
				))}

				<button className="text-lg bg-primary-200 px-4 py-1 rounded-full hover:shadow-button-glow transition-shadow ease-in-out duration-200">
					{CONTACT_LINK.name}
				</button>
			</div> */}

			<div className="max-sm:overflow-hidden">
				<Hamburger open={open} setOpen={setOpen} sticky={sticky} />
				<div
					className={classNames(
						open === "true" && "bg-black/25 w-screen h-screen absolute left-0 top-0"
					)}
					onClick={() => setOpen("false")}
				>
					<div
						ref={container}
						className="flex flex-col items-end justify-center px-8 lg:px-20 gap-6 lg:gap-8 text-4xl lg:text-5xl bg-white absolute top-0 left-0 w-screen pb-20 pt-28 z-20"
						onClick={(e) => e.stopPropagation()}
					>
						<Link
							to="/#projects"
							className="nav-item cursor-pointer font-outline"
							onClick={() => setOpen("false")}
						>
							Projects
						</Link>
						<Link
							to="/#experience"
							className="nav-item cursor-pointer font-outline"
							onClick={() => setOpen("false")}
						>
							Experience
						</Link>
						<Link
							to="/#contact"
							className="nav-item cursor-pointer font-outline"
							onClick={() => setOpen("false")}
						>
							Contact
						</Link>
						<Link
							to="/cv"
							className="nav-item cursor-pointer font-outline"
							onClick={() => setOpen("false")}
						>
							My CV
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Navlinks;
