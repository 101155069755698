import classNames from "classnames";
import emailjs from "@emailjs/browser";
import { Icon } from "@iconify/react";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import Ellipse from "assets/svgs/ellipse.svg";

type ContactProps = {};

const Contact = ({}: ContactProps) => {
	const formRef = useRef<HTMLFormElement>(null);

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm({
		defaultValues: {
			from_name: "",
			reply_to: "",
			message: "",
		},
		mode: "onTouched",
	});

	const onSubmit = async () => {
		try {
			await emailjs.sendForm(
				"service_arkst68",
				"template_kqx82i1",
				formRef.current as HTMLFormElement,
				"6Uc7Uw_O716fac51g"
			);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<section id="contact" className="pb-24 pt-44 lg:pt-72">
			<div className="flex justify-around w-full items-center">
				<div className="w-64 h-64 flex items-center justify-center relative max-xl:hidden mr-24">
					<img src={Ellipse} alt="ellipse" className="absolute ellipse w-full" />
					<Icon icon="carbon:email" className="h-36 w-36 text-primary-200 z-10" />
				</div>
				<div className="w-full max-w-3xl">
					<h1 className="text-3xl font-medium mb-8">Want to get in touch?</h1>

					<form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
						<div className="grid grid-cols-2 gap-x-8 gap-y-2 mb-4">
							<div className="flex col-span-2 sm:col-span-1 flex-col gap-3">
								<label htmlFor="from_name">Name</label>
								<input
									{...register("from_name", { required: true })}
									type="text"
									id="from_name"
									placeholder="Please enter your name"
									className={classNames(
										"bg-background-200 rounded-lg px-4 py-2 border-slate-600 border-2 focus:border-primary-200 outline-none placeholder:text-accent",
										errors.from_name &&
											"!border-red-400 placeholder:text-red-400 focus:border-red-400"
									)}
									autoComplete="new-password"
								/>
								<span
									className={classNames(
										"opacity-0",
										errors.from_name && "text-red-400 opacity-100"
									)}
								>
									This field is required
								</span>
							</div>

							<div className="flex flex-col col-span-2 sm:col-span-1 gap-3">
								<label htmlFor="reply_to">Email</label>
								<input
									{...register("reply_to", { required: true })}
									type="email"
									id="reply_to"
									placeholder="Please enter your email"
									className={classNames(
										"bg-background-200 rounded-lg px-4 py-2 border-slate-600 border-2 focus:border-primary-200 outline-none placeholder:text-accent",
										errors.reply_to &&
											"!border-red-400 placeholder:text-red-400 focus:border-red-400"
									)}
									autoComplete="new-password"
								/>
								<span
									className={classNames("opacity-0", errors.reply_to && "text-red-400 opacity-100")}
								>
									This field is required
								</span>
							</div>

							<div className="col-span-2 flex flex-col gap-3">
								<label htmlFor="message">Message</label>
								<textarea
									{...register("message", { required: true })}
									rows={5}
									id="message"
									placeholder="Please enter your message"
									className={classNames(
										"bg-background-200 rounded-lg px-4 py-2 border-slate-600 border-2 focus:border-primary-200 outline-none placeholder:text-accent",
										errors.message &&
											"!border-red-400 placeholder:text-red-400 focus:border-red-400"
									)}
									autoComplete="new-password"
								/>
								<span
									className={classNames("opacity-0", errors.message && "text-red-400 opacity-100")}
								>
									This field is required
								</span>
							</div>
						</div>

						<div className="flex items-center gap-4">
							<button
								className={classNames(
									"contact-button-gradient rounded-md px-4 py-2 flex items-center gap-2 disabled:brightness-75 disabled:cursor-not-allowed",
									!(isSubmitting || isSubmitSuccessful) && "hover:shadow-button-glow"
								)}
								type="submit"
								disabled={isSubmitting || isSubmitSuccessful}
							>
								Send Message
								{isSubmitting && (
									<div
										className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-secondary motion-reduce:animate-[spin_1.5s_linear_infinite]"
										role="status"
									>
										<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
											Loading...
										</span>
									</div>
								)}
							</button>

							{isSubmitSuccessful && <p className="text-green-500">Your message was sent.</p>}
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Contact;
