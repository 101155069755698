import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { Icon } from "@iconify/react";
import { useState } from "react";

import { EXPERIENCES } from "lib/constants";

const ExperiencesAccordion = () => {
	const [open, setOpen] = useState(0);

	const handleOpen = (value: number) => setOpen(open === value ? 0 : value);
	return (
		<>
			{EXPERIENCES.map((job, index) => (
				<Accordion
					key={index}
					open={open === index + 1}
					className="mb-5 rounded-2xl border-2 border-accent-200 bg-background-200 px-6 py-3 max-w-2xl"
					placeholder={undefined}
				>
					<AccordionHeader
						placeholder={undefined}
						onClick={() => handleOpen(index + 1)}
						className={`border-b-0 transition-colors`}
					>
						<p className="text-lg font-normal flex items-center gap-x-4 gap-y-1 flex-wrap">
							{job.title}
							<span className="text-primary-300">@{job.company}</span>
						</p>
						<button className="ml-auto rounded-full bg-primary h-9 w-9 min-w-[36px] text-accent text-4xl flex items-center justify-center">
							{open !== index + 1 ? (
								<Icon icon="ic:round-plus" className="h-7 w-7" />
							) : (
								<Icon icon="ic:round-minus" className="h-7 w-7" />
							)}
						</button>
					</AccordionHeader>
					<AccordionBody className="pt-4 text-base font-normal">
						<ul className="list-disc pl-6">
							{job.responsibilities.map((responsibility, idx) => (
								<li key={idx} className="mb-2">
									{responsibility}
								</li>
							))}
						</ul>
					</AccordionBody>
				</Accordion>
			))}
		</>
	);
};

export default ExperiencesAccordion;
