import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";

import Triangle from "assets/svgs/triangle.svg";
import Rectangle from "assets/svgs/rectangle.svg";
import Ellipse from "assets/svgs/ellipse.svg";

const HeroSection = () => {
	const container = useRef<HTMLDivElement>(null);
	const tl = useRef<any>();

	useGSAP(
		() => {
			tl.current = gsap
				.timeline()
				.fromTo(
					".hero-text p, .hero-text h1, .hero-text h3",
					{ opacity: 0, y: -10 },
					{ duration: 0.8, opacity: 1, y: 0, stagger: 0.1, ease: "power4.inOut" }
				);

			gsap.fromTo(
				".triangle-large",
				{ paused: true },
				{ duration: 1, opacity: 1, x: 240, rotation: 360, delay: 0.8 }
			);

			gsap.fromTo(
				".rectangle-small",
				{ paused: true },
				{ duration: 1, opacity: 1, x: 160, y: -100, rotation: -360, delay: 0.8 }
			);
			gsap.fromTo(
				".triangle-small",
				{ paused: true },
				{ duration: 1, opacity: 1, x: 120, y: 60, rotation: -360, delay: 0.8 }
			);

			gsap.fromTo(".ellipse", { paused: true }, { duration: 1, opacity: 1, x: 60, delay: 0.8 });
		},
		{ scope: container }
	);

	return (
		<section className="pt-44 pb-40 relative max-w-screen" ref={container}>
			<img
				src={Triangle}
				alt="triangle large"
				className="triangle-large absolute top-24 max-sm:-left-10 opacity-0"
			/>
			<img
				src={Rectangle}
				alt="rectangle small"
				className="rectangle-small absolute top-64 left-96 w-24 opacity-0 max-sm:hidden"
			/>
			<img
				src={Triangle}
				alt="triangle small"
				className="triangle-small absolute top-[24rem] left-80 w-16 opacity-0 max-sm:hidden"
			/>
			<img
				src={Ellipse}
				alt="ellipse"
				className="ellipse absolute top-[24rem] left-0 w-32 opacity-0"
			/>

			<div className="hero-text max-sm:text-center">
				<p className="text-2xl mb-2">Hi, I'm</p>
				<h1 className="text-5xl lg:text-6xl mb-3">Anas Hersi</h1>
				<h3 className="text-accent text-base lg:text-xl max-w-lg">
					I'm currently dedicated to broadening my skills in developing and enhancing
					high-performance websites.
				</h3>
			</div>
		</section>
	);
};

export default HeroSection;
