import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef } from "react";

import Rectangle from "assets/svgs/rectangle.svg";
import Triangle from "assets/svgs/triangle.svg";

import Accordion from "components/views/Experience/Accordion";
import SectionTitle from "components/common/SectionTitle";

import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { EXPERIENCE_ICONS } from "lib/constants";
import { Tooltip } from "@material-tailwind/react";

type ExperienceSectionProps = {};

gsap.registerPlugin(useGSAP, ScrollTrigger);

const ExperienceSection = ({}: ExperienceSectionProps) => {
	const container = useRef<HTMLDivElement>(null);

	useGSAP(
		() => {
			const skillsIcons = gsap.utils.toArray(".skill-icon");

			gsap.to(skillsIcons, {
				opacity: 1,
				duration: 0.2,
				stagger: 0.05,
				yoyo: true,
				scrollTrigger: {
					trigger: ".skill-icon-container",
					start: "top bottom-=50px",
					toggleActions: "restart none none reverse",
				},
			});

			gsap.fromTo(
				".triangle-large",
				{ paused: true },
				{
					scrollTrigger: {
						trigger: ".triangle-large",
						start: "top bottom",
						toggleActions: "restart none none none",
					},
					duration: 0.8,
					opacity: 1,
					x: 240,
					y: 160,
					rotation: 390,
				}
			);

			gsap.fromTo(
				".rectangle-large",
				{ paused: true },
				{
					scrollTrigger: {
						trigger: ".rectangle-large",
						start: "top bottom",
						toggleActions: "restart none none none",
					},
					duration: 0.8,
					opacity: 1,
					x: 160,
					rotation: -360,
				}
			);
		},
		{ scope: container }
	);

	return (
		<section id="experience" className="pt-44 lg:pt-72 relative overflow-hidden" ref={container}>
			<img
				src={Triangle}
				alt="triangle large"
				className="triangle-large absolute top-[36rem] max-lg:hidden left-[28rem] max-sm:-left-10 opacity-0"
			/>
			<img
				src={Rectangle}
				alt="rectangle large"
				className="rectangle-large absolute top-80 left-[36rem] w-64 opacity-0"
			/>

			<SectionTitle title="Experience" />

			<Link to="/cv">
				<button className="border rounded-lg border-slate-600 mb-6 hover:border-accent hover:backdrop-brightness-125 py-2 p-4">
					See my CV
				</button>
			</Link>

			<Accordion />

			<div className="mt-16 mx-auto lg:mx-0 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-8 w-fit skill-icon-container">
				{EXPERIENCE_ICONS.map(({ icon, size, name }, index) => (
					<Tooltip
						key={index}
						content={name}
						animate={{ mount: { scale: 1, y: 0 }, unmount: { scale: 0, y: 25 } }}
						className="bg-background-200 rounded-lg py-1 px-3"
					>
						<div className="skill-icon rounded-xl flex items-center justify-center border-2 border-accent-200 w-16 h-16 bg-background-200 opacity-0">
							<Icon icon={icon} className={size} />
						</div>
					</Tooltip>
				))}
			</div>
		</section>
	);
};

export default ExperienceSection;
