import {
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";

import RootLayout from "layouts/RootLayout";
import CVPage from "pages/CV";
import HomePage from "pages/Home";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<RootLayout />}>
			<Route index element={<HomePage />} />
			<Route path="/cv" element={<CVPage />} />
			<Route path="*" element={<HomePage />} />
		</Route>
	)
);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
