import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { useRef } from "react";

type SectionTitleProps = {
	title: string;
};

const SectionTitle = ({ title }: SectionTitleProps) => {
	const container = useRef<HTMLDivElement>(null);
	useGSAP(
		() => {
			gsap.set(".section-title-background", { scaleX: 0 });
			gsap.set(".section-title-container h1", { opacity: 0 });

			gsap.to(".section-title-background", {
				scrollTrigger: {
					trigger: ".section-title-background",
					start: "top bottom-=100px",
					toggleActions: "restart none none reverse",
				},
				scaleX: 1,
				duration: 0.4,
				transformOrigin: "50% 50%",
			});

			gsap.to(".section-title-container h1", {
				scrollTrigger: {
					trigger: ".section-title-background",
					start: "top bottom-=100px",
					toggleActions: "restart none none reverse",
				},
				opacity: 1,
				duration: 0.2,
				delay: 0.2,
			});
		},
		{ scope: container }
	);

	return (
		<div ref={container} className="w-36 h-10 relative section-title-container mb-16">
			<div className="absolute w-36 h-10 section-title-gradient text-center rounded-lg whitespace-nowrap section-title-background" />
			<h1 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 whitespace-nowrap">
				{title}
			</h1>
		</div>
	);
};

export default SectionTitle;
