import classNames from "classnames";

type GridCardProps = {
	children?: React.ReactNode;
	className?: string;
};

const GridCard = ({ className, children }: GridCardProps) => {
	return (
		<div
			className={classNames(
				"grid-card bg-background-200 border border-slate-600 rounded-2xl text-white p-4 hover:border-primary-200 transition-colors duration-200 ease-linear opacity-0 translate-y-[100px]",
				className
			)}
		>
			{children}
		</div>
	);
};

export default GridCard;
