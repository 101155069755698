import classNames from "classnames";
import { Link } from "react-router-dom";

import Logo from "assets/logo.png";
import NavLinks from "components/Header/NavLinks";
import useSticky from "utils/useSticky";

const Header = () => {
	const { sticky, stickyRef } = useSticky();
	return (
		<nav
			ref={stickyRef}
			className={classNames(
				"py-2 flex items-center justify-between z-20 px-4 xl:px-16 top-0 transition-all duration-200",
				sticky && "fixed inset-x-0 backdrop-blur-md py-0"
			)}
		>
			<Link to="/">
				<img
					className={classNames(
						"h-16 xl:h-20 rounded-xl transition-all duration-200",
						sticky && "h-12 xl:h-16"
					)}
					src={Logo}
					alt="logo"
				/>
			</Link>
			<NavLinks sticky={sticky} />
		</nav>
	);
};

export default Header;
