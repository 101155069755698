import Contact from "components/sections/Contact";
import Experience from "components/sections/Experience";
import HeroSection from "components/sections/Hero";
import Projects from "components/sections/Projects";

const HomePage = () => {
	return (
		<>
			<HeroSection />
			<Projects />
			<Experience />
			<Contact />
		</>
	);
};

export default HomePage;
