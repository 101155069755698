import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import BentoGrid from "components/views/BentoGrid/index";
import SectionTitle from "components/common/SectionTitle";

gsap.registerPlugin(useGSAP, ScrollTrigger);

const Projects = () => {
	useEffect(() => {
		const gridCards = gsap.utils.toArray(".grid-card");

		gsap.to(gridCards, {
			opacity: 1,
			y: 0,
			duration: 0.4,
			stagger: 0.1,
			scrollTrigger: {
				trigger: ".bento-grid",
				start: "top bottom-=50px",
				toggleActions: "restart none none reverse",
			},
		});
	}, []);

	return (
		<section id="projects" className="pt-44 lg:pt-72">
			<SectionTitle title="Projects" />

			<BentoGrid />
		</section>
	);
};

export default Projects;
